@use './variable' as global_variables;
@use './colors' as bg2-colors-light;

/// Makes the height of the current selected tag fixed.
/// @param {number} $height The fixed value to use.
@mixin fixed-height($height) {
  height: $height;
  min-height: $height;
  max-height: $height;
}

/// Modifies the scrollbar object
/// @param {boolean} $enable_x Enables the scrollbar on X-axis.
/// @param {boolean} $enable_y Enables the scrollbar on Y-axis.
/// @param {boolean} $force_x Forces the scrollbar to be visible on X-axis.
/// @param {boolean} $force_y Forces the scrollbar to be visible on Y-axis.
@mixin scrollbar-styles($enable_x: false, $enable_y: false, $force_x: false, $force_y: false) {
  @if $enable_x == true {
    overflow-x: auto;

    @if $force_x == true {
      overflow-x: scroll;
    }
  }

  @if $enable_y == true {
    overflow-y: auto;

    @if $force_y == true {
      overflow-y: scroll;
    }
  }

  // &::-webkit-scrollbar {
  //   width: var(--var-scrollbar-width);
  //   height: var(--var-scrollbar-height);

  //   &:hover {
  //     cursor: pointer;
  //   }
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: lightgray;
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: lighten(dimgrey, 10);

  //   &:hover {
  //     background-color: dimgrey;
  //   }
  // }
}

@mixin ghost-style($border: true, $grayscale: true, $background: false) {
  @if $border {
    border-left: 10px solid gray !important;
  }

  @if $grayscale {
    filter: grayscale(1) !important;
  }

  @if $background {
    background-color: bg2-colors-light.$color-bg-ghost-entity !important;
  }
}

@mixin def-material-run-snackbar {
  .mat-snack-bar-container.run-snack-bar {
    background: transparent !important;
    box-shadow: none !important;
    margin: 0px !important;
    padding: 0px !important;

    @media (max-width: 1200px) {
      & {
        position: fixed;
        bottom: 0;
        left: 0;
        max-width: 100%;
        min-width: 100%;
        min-height: 34px;
      }
    }
  }
}

@mixin def-only-admin-zone {
  .only-admin {
    background: repeating-linear-gradient(45deg, #e4e4e4, #e4e4e4 10px, white 10px, white 20px) !important;

    &:hover {
      background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 10px, white 10px, white 20px) !important;
    }
  }
}

@mixin def-link-styles {
  a {
    color: bg2-colors-light.$color-blue;

    &.disabled {
      color: inherit;
      cursor: default !important;
      pointer-events: none !important;
    }
  }

  .simili-link {
    color: #4189f4;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

// #region (css fixes)

// Avoid zoom on mobile iOS device
// See https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
@mixin fix-ios-zoom {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input,
    input:not([type]),
    input[type='text']:not(.browser-default),
    input[type='password']:not(.browser-default),
    input[type='email']:not(.browser-default),
    input[type='url']:not(.browser-default),
    input[type='time']:not(.browser-default),
    input[type='date']:not(.browser-default),
    input[type='datetime']:not(.browser-default),
    input[type='datetime-local']:not(.browser-default),
    input[type='tel']:not(.browser-default),
    input[type='number']:not(.browser-default),
    input[type='search']:not(.browser-default),
    textarea.materialize-textarea {
      font-size: 16px;
    }
  }
}

// #endregion
