@use '../variable' as bg2-variables;

.mat-snack-bar-container.snackbar-runners-manager {
  background: transparent !important;
  box-shadow: none !important;
  margin: 0px !important;
  padding: 0px !important;

  @media (max-width: 1200px) {
    & {
      position: fixed;
      bottom: 0;
      left: 0;
      max-width: 100%;
      min-width: 100%;
      min-height: 34px;
    }
  }

  div.snackbar-runner {
    padding: 5px;
    display: flex;
    color: white;
    border-radius: bg2-variables.$border-radius;
    align-items: center;
    flex-flow: column nowrap;
    background-color: #323232;

    &.snackbar-runner-error {
      background-color: #f5554a !important;
    }

    > span.title {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;

      .link {
        font-size: medium;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    > div.run-progress {
      width: 100%;
      margin: 5px 0px;
      position: relative;

      > mat-progress-bar.mat-progress-bar {
        height: 15px;
        border-radius: bg2-variables.$border-radius;
      }

      span.progress-value {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        color: black;
        font-size: 13px;
        line-height: 15px;
        font-weight: bold;
        text-align: center;
      }
    }

    > span.description {
      font-size: smaller;
      text-align: center;
    }
  }
}
