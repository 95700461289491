/// Fixes flex gap for Apple Safari
/// @param {string} $direction Direction of the flex.
@mixin gap-safari-robust($value, $direction: 'row') {
  gap: $value;

  @if $direction == 'row' {
    // Code for direction row
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      >*:not(:last-child) {
        margin-right: $value;
      }
    }
  } @else {
    // Code for direction column
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      >*:not(:last-child) {
        margin-bottom: $value;
      }
    }
  }
}