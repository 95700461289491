@use './media-queries' as mq;

// #region -> (banner sizes)

$height-banner: 100px;
$height-demi-banner: $height-banner * 0.5;
$height-single-banner: $height-banner * 1;
$height-1-5-banner: $height-banner * 1.5;
$height-double-banner: $height-banner * 2;
$height-triple-banner: $height-banner * 3;

// #endregion

$border-radius: 5px;

$font-weight: 400;

$font-weight-thin: 300;
$font-weight-bold: 600;
