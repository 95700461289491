@use '../colors' as bg2-colors-light;

$line-height: 1.15;
$infix-padding-top: 0.4em;
$subscript-font-scale: 0.75;
$infix-padding-bottom: 0.6em;
$infix-margin-top: 1em * $line-height * $subscript-font-scale;

.mat-form-field-appearance-outline {
  .mat-form-field-wrapper {
    padding-bottom: 0.4em;

    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: $infix-padding-top 0 $infix-padding-bottom 0;
        border-top: $infix-margin-top solid transparent;

        .mat-select-value {
          transform: translateY(-0.2em);
        }

        .mat-form-field-label {
          top: 1.4em;
          margin-top: -0.4em;
        }
      }
    }

    .mat-form-field-subscript-wrapper {
      .mat-form-field-hint-wrapper {
        mat-hint.mat-hint {
          background-color: #fafafa;
          padding: 0 5px;
        }
      }
    }
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.10373em) scale(0.75);
    width: 133.3333533333%;
  }
}

.mat-checkbox-label {
  min-height: 1.3em;
  color: rgb(77, 78, 77);
}

div.mat-select-panel-wrap {
  > div.mat-select-panel {
    > mat-option.mat-option {
      &.mat-selected {
        background-color: bg2-colors-light.$color-fg-primary;
      }

      > span.mat-option-text {
        color: rgba(0, 0, 0, 0.87) !important;
      }
    }
  }
}
