/// Non-overlapping Material Design breakpoints
/// @type map
$breakpoints: (
  xs: (
    begin: 0,
    end: 599.9px,
  ),
  sm: (
    begin: 600px,
    end: 959.9px,
  ),
  md: (
    begin: 960px,
    end: 1279.9px,
  ),
  lg: (
    begin: 1280px,
    end: 1919.9px,
  ),
  xl: (
    begin: 1920px,
    end: 4999.99px,
  ),
) !default;

/// Overlapping breakpoints that are greater than defined
/// @type map
$overlapping-gt: (
  gt-xs: 600px,
  gt-sm: 960px,
  gt-md: 1280px,
  gt-lg: 1920px,
) !default;

/// Overlapping breakpoints that are less than defined
/// @type map
$overlapping-lt: (
  lt-sm: 599.9px,
  lt-md: 959.9px,
  lt-lg: 1279.9px,
  lt-xl: 1919.9px,
) !default;

/// Applies a media query.
///
/// @param {string} $key
///   The media query key name
@mixin media-query($key) {
  /** 
    * The $key can be one of :
    * xs    =>	'screen and (max-width: 599px)'
    * sm    =>	'screen and (min-width: 600px) and (max-width: 959px)'
    * md    =>	'screen and (min-width: 960px) and (max-width: 1279px)'
    * lg    =>	'screen and (min-width: 1280px) and (max-width: 1919px)'
    * xl    =>	'screen and (min-width: 1920px) and (max-width: 5000px)'
    
    * lt-sm	=> 'screen and (max-width: 599px)'
    * lt-md	=> 'screen and (max-width: 959px)'
    * lt-lg	=> 'screen and (max-width: 1279px)'
    * lt-xl	=> 'screen and (max-width: 1919px)'
    
    * gt-xs	=> 'screen and (min-width: 600px)'
    * gt-sm	=> 'screen and (min-width: 960px)'
    * gt-md	=> 'screen and (min-width: 1280px)'
    * gt-lg	=> 'screen and (min-width: 1920px)' 
  */
  @if map-has-key($breakpoints, $key) {
    $min: map-get(map-get($breakpoints, $key), begin);
    $max: map-get(map-get($breakpoints, $key), end);

    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if map-has-key($overlapping-gt, $key) {
    $min: map-get($overlapping-gt, $key);

    @media (min-width: $min) {
      @content;
    }
  } @else if map-has-key($overlapping-lt, $key) {
    $max: map-get($overlapping-lt, $key);

    @media (max-width: $max) {
      @content;
    }
  }
}

$devices-only: 'only screen and (max-width : 959px), only screen and (max-height : 959px)' !default;
