// Definition of the beta ribbon
.beta {
  position: relative;
  overflow: hidden;

  &::after {
    content: 'beta';
    position: absolute;
    width: 80px;
    height: 14px;
    background: rgba(1, 1, 1, 0.5);
    top: 10px;
    left: -23px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    line-height: 15px;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
