// Loads variables and mixins
@use '@angular/material' as mat;

@use './styles/mixins' as mxs;
@use './styles/media-queries' as mq;
@use './styles/fixers' as bg2-fixers;
@use './styles/variable' as bg2-variables;
@use './styles/colors' as bg2-colors-light;

// Imports angular material theme
@import '@ng-matero/extensions/theming';
@import '@mdi/font/scss/materialdesignicons.scss';
@import 'ng-pick-datetime-ex/assets/style/picker.min.css';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

$custom-typography: mat.define-typography-config(
  $font-family: '"Source Sans Pro", "Roboto", sans-serif;',
);
@include mat.all-component-typographies($custom-typography);
@include mat.core($custom-typography);

$bg-app-warn: mat.define-palette(bg2-colors-light.$mat-bg-warn);
$bg-app-accent: mat.define-palette(bg2-colors-light.$mat-bg-accent);
$bg-app-primary: mat.define-palette(bg2-colors-light.$mat-bg-primary);
$bg-app-theme: mat.define-light-theme(
  (
    color: (
      warn: $bg-app-warn,
      accent: $bg-app-accent,
      primary: $bg-app-primary,
    ),
  )
);

@include material-extensions-theme($bg-app-theme);
@include mat.all-component-themes($bg-app-theme);

html {
  body {
    // #region -> (dynamic variables)

    --var-padding-thin: 5px;
    --var-padding-wide: 10px;

    --var-scrollbar-width: 14px;
    --var-scrollbar-height: 14px;

    @include mq.media-query(lt-md) {
      --var-padding-thin: 2.5px;
      --var-padding-wide: 5px;

      --var-scrollbar-width: 9px;
      --var-scrollbar-height: 9px;
    }

    // #endregion

    margin: 0px;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    font-family: 'Source Sans Pro', sans-serif !important;

    @include mq.media-query(lt-sm) {
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }

    // Imports once (custom styles)
    @import './styles/commons/all'; // (commons styles)
    @import './styles/behaviours/all'; // (behaviours styles)
    @import './styles/materialize/all'; // (materialize styles)

    &.no-scroll {
      overflow: hidden !important;
    }

    *,
    *::after,
    *::before {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }

    .app-container {
      width: 100%;
      margin: 0 auto;
      // max-width: 1400px;

      &.full-width-app-container {
        width: 100% !important;
        max-width: unset !important;
      }

      @include mq.media-query(gt-xs) {
        width: 90%;
      }

      @include mq.media-query(gt-md) {
        width: 80%;
      }
    }

    // #region (common styles)

    @include mxs.def-material-run-snackbar();
    @include mxs.def-only-admin-zone();
    @include mxs.def-link-styles();
    @include mxs.fix-ios-zoom();

    .locked-due-to-rights {
      // pointer-events: none;

      &:hover {
        cursor: not-allowed !important;
      }
    }

    .mdi {
      :not(.--not-flex) {
        display: flex;
      }
    }

    .mat-button-toggle-button {
      &:focus {
        background-color: unset;
      }
    }

    .mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($bg-app-primary);
      color: mat.get-color-from-palette($bg-app-primary, default-contrast);

      button {
        color: white;
      }
    }

    .mat-badge-grey {
      > span.mat-badge-content {
        background-color: #e7e7e7;
      }
    }

    // #endregion

    // #region -> (navigation menu)

    div.cdk-overlay-container {
      div.navigation-menu {
        > mat-divider {
          margin: 5px 0px;
        }

        > div.mat-menu-item {
          > span,
          a {
            @include bg2-fixers.gap-safari-robust(5px);
            display: flex;
            align-items: center;
            justify-content: flex-start;

            > span.mdi {
            }

            > span:not(.mdi) {
            }
          }
        }
      }
    }

    // #endregion

    // #region -> (bottom sheet styles)

    div.cdk-overlay-container {
      > div.cdk-global-overlay-wrapper {
        > div.cdk-overlay-pane {
          &.bg2-bottom-sheet {
            width: 100vw;
            padding: 0px;
            max-width: 1400px;
            min-width: initial;

            @include mq.media-query(gt-xs) {
              width: 90vw;
            }

            @include mq.media-query(gt-md) {
              width: 80vw;
            }

            mat-bottom-sheet-container {
              width: inherit;
            }
          }
        }
      }
    }

    // #endregion

    // #region -> (mapping styles)

    div.leaflet-container {
      > div.leaflet-map-pane {
        > div.leaflet-marker-pane {
          > div.leaflet-marker-icon {
            img.outdated-marker-state {
              position: absolute;
              left: -13px;
              top: -9px;
            }

            img.movement-periodic-location-marker-state {
              top: -9px;
              right: -13px;
              position: absolute;
              border-radius: 100%;
              background-color: white;
            }

            img.movement-movement-marker-state {
              top: -9px;
              right: -19px;
              position: absolute;
              border-radius: 100%;
              background-color: #ee6055;
            }

            &.cluster-single-movement {
              > div.circle {
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
              }
            }
          }
        }

        > div.leaflet-popup-pane {
          > div.leaflet-popup {
            > div.leaflet-popup-content-wrapper {
              border-radius: bg2-variables.$border-radius;

              > div.leaflet-popup-content {
                margin: 0px;
                padding: {
                  top: 22px;
                  left: var(--var-padding-thin);
                  right: var(--var-padding-thin);
                  bottom: var(--var-padding-thin);
                }
              }
            }

            > a.leaflet-popup-close-button {
              font-size: 24px;

              &:hover {
                color: bg2-colors-light.$color-red;
              }
            }
          }
        }
      }
    }

    .leaflet-tile {
      filter: blur(10px);

      &.leaflet-tile-loaded {
        filter: blur(0);
        transition: 0.5s all ease-out;
      }
    }

    // #endregion

    // #region -> (class animations)

    .animate-shake {
      perspective: 1000px;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    }

    .animate-rotate {
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }

    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    // #endregion

    // #region (snackbar styles)

    snack-bar-container.app-version-snackbar {
      color: white;
      width: 60vw;
      max-width: none;

      @include mq.media-query(lt-lg) {
        width: 90vw;
      }
    }

    // #endregion

    // Fix conflicts between materialize and materiel
    table {
      border-collapse: initial;
    }

    mat-calendar.mat-calendar {
      table {
        thead {
          border-bottom: none;
        }
      }
    }

    // Leaflet force styles (due to materialize)
    div.leaflet-control-layers-base {
      label {
        div {
          display: flex;
          flex-flow: row nowrap;

          input.leaflet-control-layers-selector {
            position: initial !important;
            opacity: 1 !important;
            margin-right: 5px !important;
          }
        }
      }
    }

    // FIXME: I comment that, cause issue map-page, if still here in few month rm it
    // div.leaflet-bottom.leaflet-right {
    //   z-index: 500;
    // }

    div.leaflet-control-attribution {
      display: none;
    }

    // Global html details styles
    details:not(.default) {
      &[open] {
        summary {
          color: bg2-colors-light.$color-fg-primary;

          &::marker {
            content: '\25BC';
          }
        }
      }

      summary {
        outline: none;
        display: list-item;

        &:hover {
          cursor: pointer;
          color: bg2-colors-light.$color-fg-primary;
        }

        &::marker {
          content: '\25BA';
          margin-right: 5px;
          font-size: 1rem;
        }

        &:hover {
          cursor: pointer;
          color: bg2-colors-light.$color-fg-primary;
        }
      }
    }
  }
}

// TODO: Re-arrange styles for cosmetics //

div.mat-menu-panel > .mat-menu-content div.navigation-menu div.mat-menu-item {
  font-weight: 300 !important;
}

// Specific style for run-snack-bar
.cdk-overlay-container {
  z-index: 16000 !important;
  // have to be over .bg2-overlay.dialog-overlay (10499)
  // This do not work as dateppicker use it also..
}

.orange {
  background-color: bg2-colors-light.$color-fg-primary !important;
}

.clickable {
  &:hover {
    cursor: pointer;
  }

  &.clickable-colorized {
    color: #4189f4;
  }
}

/* Fix for datetime picker */
.cdk-global-scrollblock {
  overflow-y: auto;
}

/* Custom CSS for panel */
div.ui-panel.no-padding div.ui-panel-content {
  padding: 0;
}

.hide {
  display: None;
}

.clear {
  clear: both;
}

hr.clear {
  border: 0;
  margin: 0;
  padding: 0;
}

.row .col {
  padding: 0 0.3rem;
}

img.center {
  display: block;
  margin: 0 auto;
}

span.no-break {
  display: inline-block;
}

i.mdi.bg-menu-icon {
  padding-left: 8px;
  margin-top: -7px;
  height: initial;
}

// .mdi-wguard {
//   content: url('assets/img/devices/wguard.svg');
//   top: 50%;
//   position: relative;
//   transform: translateY(-50%);
// }

/*****************************************************************************/
/* Forms & Material forms */

mat-form-field.mat-form-field {
  width: 100%;

  &.not-full-width {
    width: initial;
  }

  &.whitey {
    .mat-form-field-wrapper {
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  &.no-padding {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-infix {
        border-top: 0px;
      }
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }

  &.mat-focused .mat-form-field-label {
    color: black;

    .mat-placeholder-required {
      color: red !important;
    }
  }
}

/*****************************************************************************/
/* Forms & Materialize forms */

fieldset {
  border: none;
  margin: 0px;
}

div.progress {
  position: relative;
  margin-top: 0px;
  margin-bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: bg2-colors-light.$color-fg-primary;

  .indeterminate {
    background-color: darken(bg2-colors-light.$color-fg-primary, 40%);
  }
}

// Force invalid (could overide html5 validation)
// Usefull with empty invalid select
input.finvalid:not([type]),
input.finvalid:not([type]):focus,
input[type='time'].finvalid:not(.browser-default),
input[type='time'].finvalid:not(.browser-default):focus,
input[type='date'].finvalid:not(.browser-default),
input[type='date'].finvalid:not(.browser-default):focus,
input[type='datetime'].finvalid:not(.browser-default),
input[type='datetime'].finvalid:not(.browser-default):focus,
input[type='datetime-local'].finvalid:not(.browser-default),
input[type='datetime-local'].finvalid:not(.browser-default):focus {
  border-bottom: 1px solid #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}

input:not([type]),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default) {
  margin-bottom: 0px;
}

label {
  font-size: 1em;
  color: #5d5d5d;
}

input:not([type]),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default) {
  height: 2.6rem;
}

input:not([type]):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly]) + label {
  color: bg2-colors-light.$color-fg-primary;
}

input:not([type]):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid bg2-colors-light.$color-fg-primary;
  box-shadow: 0 1px 0 0 bg2-colors-light.$color-fg-primary;
}

/* Theme for date-picker */

.cdk-overlay-container {
  .owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
    padding: 0;
  }

  // Custom
  .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: bg2-colors-light.$color-fg-primary;
  }

  .owl-dt-container-buttons {
    color: bg2-colors-light.$color-fg-primary;
  }
}

/* #region BeeGuard custom dialogs */

$dialogDefaultPadding: 10px;

.bg-dialog {
  top: 50px;
  left: 50%;
  width: 600px;
  z-index: 10500;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  background-color: #fafafa;
  max-height: calc(100vh - 100px);
  transform: translateX(-50%);
  -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);

  @include mq.media-query(lt-lg) {
    top: 10px;
    width: 90%;
  }

  div.dialog-header {
    display: flex;
    justify-content: center;
    background-color: #f5b54a;
    padding: 5px $dialogDefaultPadding;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  div.dialog-body {
    overflow: auto;
    text-align: center;
    padding: 20px $dialogDefaultPadding;
  }

  div.dialog-footer {
    padding: $dialogDefaultPadding;
    padding-top: 0px;
  }
}

.bg2-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;

  &.modal-overlay {
    z-index: 9900;
  }

  &.dialog-overlay {
    z-index: 10499;
  }
}

/* #endregion */

.mat-menu-panel > .mat-menu-content {
  div.navigation-menu {
    div.mat-menu-item {
      font-size: 16px;

      &.nopadding {
        padding: 0px;
      }

      &.no-overflow {
        overflow: visible;
      }

      a,
      span {
        color: black;
        display: flex;
        flex-flow: row nowrap;

        .mdi {
          margin-right: 10px;
        }
      }
    }
  }
}
