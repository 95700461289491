@import './colors';
@import './margins';
@import './responsives';

.noselect {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.w-100 {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
