$color-red: #ef3a3a;
$color-blue: #4189f4;
$color-green: #26a69a;
$color-purple: #644afa;
$color-purple-tonal: #d9d1ff;
$color-grey: rgb(203, 204, 203);

// Foreground colors
$color-fg-primary: #f5b54a;
$color-fg-superadmin: #761c7d;

// Background colors
$color-bg-primary: #fbfaf9;
$color-bg-superadmin: #dfd2ee;

// Background color for ghost entity
$color-bg-ghost-entity: #f3f3f3;

//
$color-bg-grey: #dddddd;

// Custom color palettes (generated from http://mcg.mbitson.com)
$mat-bg-primary: (
  50: #fef6e9,
  100: #fce9c9,
  200: #fadaa5,
  300: #f8cb80,
  400: #f7c065,
  500: #f5b54a,
  600: #f4ae43,
  700: #f2a53a,
  800: #f09d32,
  900: #ee8d22,
  A100: #ffffff,
  A200: #fff9f3,
  A400: #ffe0c0,
  A700: #ffd3a7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-bg-accent: (
  50: #eaeaea,
  100: #cacaca,
  200: #a6a7a6,
  300: #828382,
  400: #686968,
  500: #4d4e4d,
  600: #464746,
  700: #3d3d3d,
  800: #343534,
  900: #252525,
  A100: #f18282,
  A200: #ec5454,
  A400: #ff0e0e,
  A700: #f30000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-bg-warn: (
  50: #feebe9,
  100: #fcccc9,
  200: #faaaa5,
  300: #f88880,
  400: #f76f65,
  500: #f5554a,
  600: #f44e43,
  700: #f2443a,
  800: #f03b32,
  900: #ee2a22,
  A100: #ffffff,
  A200: #fff3f3,
  A400: #ffc2c0,
  A700: #ffa9a7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
