@use "../../styles/media-queries" as bg2-media-query;

@media screen and (orientation: portrait) {
  .only-landscape {
    display: none;
  }
}

@media screen and (orientation: landscape) {
  .only-portrait {
    display: none;
  }
}


@include bg2-media-query.media-query(lt-md) {
  .hide-on-med-and-down {
    display: none !important;
  }
}

@include bg2-media-query.media-query(lt-sm) {
  .hide-on-small-and-down {
    display: none !important;
  }
}

@include bg2-media-query.media-query(gt-sm) {
  .hide-on-medium-and-up {
    display: none;
  }
}

.linebreak,
.linebreak-small-and-down,
.linebreak-medium-and-down {
  width: 100%;
  height: 0;
  min-height: 0;
}

@include bg2-media-query.media-query(gt-sm) {
  .linebreak-small-and-down {
    display: none;
  }
}

@include bg2-media-query.media-query(gt-md) {
  .linebreak-medium-and-down {
    display: none;
  }
}

@include bg2-media-query.media-query(gt-md) {
  .linebreak-medium-and-down {
    display: none;
  }
}