@use '../colors' as bg2-colors-light;
@use '../media-queries' as bg2-media-query;

// Colors definition
$table_limit_color: #737373;

div.zone-datatable {
  margin: 20px 0px;
  margin-top: 10px;
  position: relative;

  div.loader {
    position: absolute;
    z-index: 9000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);

    > * {
      margin-top: 14%;
    }
  }

  div.table-container {
    overflow-x: auto;

    &.empty {
      overflow-x: hidden !important;
    }

    table.mat-table {
      width: 100%;

      thead {
        th {
          color: white;
          text-align: center;
          border-radius: 0px;
          padding-left: 10px;
          padding-right: 10px;
          white-space: nowrap;
          background-color: $table_limit_color;

          div.mat-sort-header-container {
            justify-content: center;

            button.mat-sort-header-button {
              &:focus {
                background-color: transparent !important;
              }
            }

            .mat-sort-header-arrow {
              color: white;
            }
          }
        }
      }

      tbody {
        tr.mat-row {
          td.mat-cell {
            padding: 10px !important;
          }
        }
      }
    }
  }

  div.no-data-loading,
  div.no-data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 300px;

    .text {
      min-width: 250px;
    }
  }

  mat-paginator.mat-paginator {
    background-color: $table_limit_color;
    color: white;

    div.mat-paginator-container {
      justify-content: space-between;

      div.mat-paginator-page-size {
        text-align: center;
        align-items: center;
      }

      mat-select.mat-select {
        .mat-select-trigger {
          .mat-select-value {
            color: white !important;
          }

          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: white;
            }
          }
        }
      }

      .mat-form-field-underline {
        background-color: white;
      }
    }
  }

  @include bg2-media-query.media-query(lt-lg) {
    table.mat-table {
      border: 0;
      vertical-align: middle;

      thead {
        .mat-header-row {
          height: 20px !important;
          background-color: $table_limit_color;

          .mat-header-cell {
            position: absolute;
            clip: rect(0 0 0 0);
          }
        }
      }

      tbody {
        .mat-row {
          position: relative;
          display: flex;
          height: initial;
          flex-flow: column;
          border-bottom: 5px solid #ddd;

          &.selected {
            &.mat-column-select {
              position: absolute;
              right: 2px;
              top: 2px;
              text-align: center;

              &::before {
                content: '';
              }
            }
          }

          .mat-cell {
            font-size: 12px;
            padding: 5px 5px;
            text-align: right;

            &:first-child {
              border-bottom: none;
            }

            &:last-child {
              border-bottom: none;
            }

            &::before {
              content: attr(data-label);
              float: left;
              font-size: 12px;
              font-weight: normal;
            }

            &.mat-column-select {
              position: absolute;
              right: 0;
              top: 0;
              text-align: center;

              &::before {
                content: '';
              }
            }

            &.mat-column-device-name {
              text-align: left;

              &::before {
                content: '';
              }
            }
          }
        }
      }
    }

    mat-paginator.mat-paginator {
      .mat-paginator-container {
        justify-content: center;
      }
    }
  }
}
