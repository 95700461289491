.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .col {
    float: left;
    padding: 0 0.75rem;
    min-height: 1px;

    &.s1 {
      width: 8.3333333333%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    &.s2 {
      width: 16.6666666667%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    &.s4 {
      width: 33.3333333333%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    &.s5 {
      width: 41.6666666667%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    &.s6 {
      width: 50%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    &.s7 {
      width: 58.3333333333%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    &.s8 {
      width: 66.6666666667%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    &.s12 {
      width: 100%;
      margin-left: auto;
      left: auto;
      right: auto;
    }
  }
}

@media only screen and (min-width: 601px) {
  .row .col.m4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

@media only screen and (min-width: 993px) {
  .row .col.l4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

@media only screen and (min-width: 1201px) {
  .row .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}
