@use '../variable' as vars;
@use '../colors' as bg2-colors-light;

.red {
  background-color: bg2-colors-light.$color-red !important;

  &.lighten-4 {
    background-color: #ffcdd2 !important;
  }
}

.red-text {
  color: bg2-colors-light.$color-red !important;
}

.blue {
  background-color: bg2-colors-light.$color-blue !important;

  &.lighten-4 {
    background-color: #bbdefb !important;
  }
}

.blue-text {
  color: bg2-colors-light.$color-blue !important;
}

.green {
  background-color: #4caf50 !important;

  &.lighten-4 {
    background-color: #c8e6c9 !important;
  }
}

.green-text.text-lighten-4 {
  color: #c8e6c9 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}

.yellow-text {
  color: #ffeb3b !important;
}

.orange {
  background-color: #ff9800 !important;

  &.lighten-4 {
    background-color: #ffe0b2 !important;
  }
}

.orange-text {
  color: #ff9800 !important;

  &.text-lighten-4 {
    color: #ffe0b2 !important;
  }
}

.grey {
  background-color: #9e9e9e !important;
}

.black {
  background-color: #000000 !important;
}

.black-text {
  color: #000000 !important;
}

.white {
  background-color: #ffffff !important;
}

.transparent {
  background-color: transparent !important;
}
