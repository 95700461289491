@use '../variable' as bg2-variables;
@use '../../styles/colors' as bg2-colors-light;

.ng-select {
  &.ng-select-multiple {
    .ng-select-container {
      > div.ng-value-container {
        > div.ng-value {
          border-radius: bg2-variables.$border-radius;
          background-color: bg2-colors-light.$color-fg-primary !important;
        }
      }
    }
  }

  .ng-select-container {
    .ng-spinner-loader {
      border-left-color: bg2-colors-light.$color-fg-primary !important;
      border: 2px solid lighten(bg2-colors-light.$color-fg-primary, 20);
    }
  }
}

ng-dropdown-panel.ng-dropdown-panel {
  z-index: 100000; // Important to be over model for select with appendTo=body

  > .ng-dropdown-footer,
  > .ng-dropdown-header {
    padding: 14px 16px;
  }

  > div.ng-dropdown-panel-items {
    > div {
      > div.ng-optgroup {
        display: flex;
        flex-direction: row;
        background-color: #efc47c;
      }

      > div.ng-option {
        &.ng-option-selected {
          background-color: bg2-colors-light.$color-fg-primary !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
        }

        border-bottom: 1px solid lightgray;

        &:last-child {
          border-bottom: none !important;
        }

        // Per component styles
        div.device-support-close-modal-component_select-device-next-usage_dropdown-option {
          gap: 5px;
          display: flex;
          align-items: center;

          > img {
            width: 50px;
            height: 50px;
          }

          > div {
            display: flex;
            flex-flow: column nowrap;

            > span {
            }

            > div {
              display: flex;
              padding-left: 10px;
              flex-flow: column nowrap;

              > span {
              }
            }
          }
        }
      }
    }
  }
}
