input:not([type]),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default) {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input:not([type]):disabled,
input:not([type])[readonly='readonly'],
input[type='time']:not(.browser-default):disabled,
input[type='time']:not(.browser-default)[readonly='readonly'],
input[type='date']:not(.browser-default):disabled,
input[type='date']:not(.browser-default)[readonly='readonly'],
input[type='datetime']:not(.browser-default):disabled,
input[type='datetime']:not(.browser-default)[readonly='readonly'],
input[type='datetime-local']:not(.browser-default):disabled,
input[type='datetime-local']:not(.browser-default)[readonly='readonly'] {
  color: rgba(0, 0, 0, 0.42);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
}

input:not([type]):disabled + label,
input:not([type])[readonly='readonly'] + label,
input[type='time']:not(.browser-default):disabled + label,
input[type='time']:not(.browser-default)[readonly='readonly'] + label,
input[type='date']:not(.browser-default):disabled + label,
input[type='date']:not(.browser-default)[readonly='readonly'] + label,
input[type='datetime']:not(.browser-default):disabled + label,
input[type='datetime']:not(.browser-default)[readonly='readonly'] + label,
input[type='datetime-local']:not(.browser-default):disabled + label,
input[type='datetime-local']:not(.browser-default)[readonly='readonly'] + label {
  color: rgba(0, 0, 0, 0.42);
}

input:not([type]):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid #26a69a;
  -webkit-box-shadow: 0 1px 0 0 #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}

input:not([type]):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly]) + label {
  color: #26a69a;
}

input:not([type]).validate + label,
input[type='time']:not(.browser-default).validate + label,
input[type='date']:not(.browser-default).validate + label,
input[type='datetime']:not(.browser-default).validate + label,
input[type='datetime-local']:not(.browser-default).validate + label {
  width: 100%;
}

input:not([type]).invalid + label:after,
input:not([type]).valid + label:after,
input[type='time']:not(.browser-default).invalid + label:after,
input[type='time']:not(.browser-default).valid + label:after,
input[type='date']:not(.browser-default).invalid + label:after,
input[type='date']:not(.browser-default).valid + label:after,
input[type='datetime']:not(.browser-default).invalid + label:after,
input[type='datetime']:not(.browser-default).valid + label:after,
input[type='datetime-local']:not(.browser-default).invalid + label:after,
input[type='datetime-local']:not(.browser-default).valid + label:after {
  display: none;
}

input:not([type]).invalid + label.active:after,
input:not([type]).valid + label.active:after,
input[type='time']:not(.browser-default).invalid + label.active:after,
input[type='time']:not(.browser-default).valid + label.active:after,
input[type='date']:not(.browser-default).invalid + label.active:after,
input[type='date']:not(.browser-default).valid + label.active:after,
input[type='datetime']:not(.browser-default).invalid + label.active:after,
input[type='datetime']:not(.browser-default).valid + label.active:after,
input[type='datetime-local']:not(.browser-default).invalid + label.active:after,
input[type='datetime-local']:not(.browser-default).valid + label.active:after {
  display: block;
}

/* Validation Sass Placeholders */
input.valid:not([type]),
input.valid:not([type]):focus,
input[type='time'].valid:not(.browser-default),
input[type='time'].valid:not(.browser-default):focus,
input[type='date'].valid:not(.browser-default),
input[type='date'].valid:not(.browser-default):focus,
input[type='datetime'].valid:not(.browser-default),
input[type='datetime'].valid:not(.browser-default):focus,
input[type='datetime-local'].valid:not(.browser-default),
input[type='datetime-local'].valid:not(.browser-default):focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #4caf50;
}

input.invalid:not([type]),
input.invalid:not([type]):focus,
input[type='time'].invalid:not(.browser-default),
input[type='time'].invalid:not(.browser-default):focus,
input[type='date'].invalid:not(.browser-default),
input[type='date'].invalid:not(.browser-default):focus,
input[type='datetime'].invalid:not(.browser-default),
input[type='datetime'].invalid:not(.browser-default):focus,
input[type='datetime-local'].invalid:not(.browser-default),
input[type='datetime-local'].invalid:not(.browser-default):focus {
  border-bottom: 1px solid #f44336;
}

input:not([type]).valid + label:after,
input:not([type]):focus.valid + label:after,
input[type='time']:not(.browser-default).valid + label:after,
input[type='time']:not(.browser-default):focus.valid + label:after,
input[type='date']:not(.browser-default).valid + label:after,
input[type='date']:not(.browser-default):focus.valid + label:after,
input[type='datetime']:not(.browser-default).valid + label:after,
input[type='datetime']:not(.browser-default):focus.valid + label:after,
input[type='datetime-local']:not(.browser-default).valid + label:after,
input[type='datetime-local']:not(.browser-default):focus.valid + label:after {
  content: attr(data-success);
  color: #4caf50;
  opacity: 1;
  -webkit-transform: translateY(9px);
  transform: translateY(9px);
}

input:not([type]).invalid + label:after,
input:not([type]):focus.invalid + label:after,
input[type='time']:not(.browser-default).invalid + label:after,
input[type='time']:not(.browser-default):focus.invalid + label:after,
input[type='date']:not(.browser-default).invalid + label:after,
input[type='date']:not(.browser-default):focus.invalid + label:after,
input[type='datetime']:not(.browser-default).invalid + label:after,
input[type='datetime']:not(.browser-default):focus.invalid + label:after,
input[type='datetime-local']:not(.browser-default).invalid + label:after,
input[type='datetime-local']:not(.browser-default):focus.invalid + label:after {
  content: attr(data-error);
  color: #f44336;
  opacity: 1;
  -webkit-transform: translateY(9px);
  transform: translateY(9px);
}

input:not([type]) + label:after,
input[type='time']:not(.browser-default) + label:after,
input[type='date']:not(.browser-default) + label:after,
input[type='datetime']:not(.browser-default) + label:after,
input[type='datetime-local']:not(.browser-default) + label:after {
  display: block;
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

// ====================== RANGE FIELD

input[type='range'],
input[type='range'] + .thumb {
  cursor: pointer;
}

input[type='range'] {
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  margin: 15px 0;
  padding: 0;
}

input[type='range']:focus {
  outline: none;
}

input[type='range'] + .thumb {
  position: absolute;
  top: 10px;
  left: 0;
  border: none;
  height: 0;
  width: 0;
  border-radius: 50%;
  background-color: #26a69a;
  margin-left: 7px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type='range'] + .thumb .value {
  display: block;
  width: 30px;
  text-align: center;
  color: #26a69a;
  font-size: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type='range'] + .thumb.active {
  border-radius: 50% 50% 50% 0;
}

input[type='range'] + .thumb.active .value {
  color: #fff;
  margin-left: -1px;
  margin-top: 8px;
  font-size: 10px;
}

input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 3px;
  background: #c2c0c2;
  border: none;
}

input[type='range'],
input[type='range'] + .thumb {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #26a69a;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  margin: -5px 0 0 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type='range'] {
  border: 1px solid white;
}

input[type='range']::-moz-range-track {
  height: 3px;
  background: #ddd;
  border: none;
}

input[type='range']::-moz-range-thumb {
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #26a69a;
  margin-top: -5px;
}

input[type='range']:-moz-focusring {
  outline: 1px solid #fff;
  outline-offset: -1px;
}

input[type='range']:focus::-moz-range-track {
  background: #ccc;
}

input[type='range']::-ms-track {
  height: 3px;
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #777;
}

input[type='range']::-ms-fill-upper {
  background: #ddd;
}

input[type='range']::-ms-thumb {
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #26a69a;
}

input[type='range']:focus::-ms-fill-lower {
  background: #888;
}

input[type='range']:focus::-ms-fill-upper {
  background: #ccc;
}
