@use '../variable' as bg2-variables;

svg[id^='d3-js-chart-'] {
  &[data-name='bg2-apiary-weather-icons-d3-chart-component'] {
    > g.d3-grids-container {
      > g.d3-X-grid {
        > g.tick {
          &:first-child {
            display: initial !important;
          }
        }
      }
    }
  }

  > g.d3-data-objects-container {
  }

  > g.d3-axes-container {
  }

  > g.d3-event-objects-container {
  }

  > rect.d3-events-container {
  }

  > g.d3-grids-container {
    > g.d3-X-grid {
      > g.tick {
        &:first-child {
          display: none;
        }

        > line {
          stroke: darkgray;
        }
      }
    }
  }

  > foreignObject.d3-loading-container {
    > div.d3-loading-container__background {
      width: 100%;
      height: 100%;
      background-color: rgba(221, 219, 221, 0.5);

      &::after {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        transform: translateX(-100%);
        animation: loading 1.5s infinite;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0)
        );

        @keyframes loading {
          100% {
            transform: translateX(100%);
          }
        }
      }
    }
  }
}

div.d3-chart-tooltip {
  padding: 5px;
  font-size: 12px;
  color: lightgray;
  transform: translate(0px, -50%);
  background-color: rgb(38, 38, 38);
  border-radius: bg2-variables.$border-radius;

  > div.d3-chart-tooltip-time {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-flow: column nowrap;

    > span.timezoned-time {
      font-size: 14px;
    }

    > span.browsered-time {
      gap: 5px;
      display: flex;
      font-size: 12px;
      flex-flow: row nowrap;
      justify-content: center;

      > span.mdi {
        color: darkorange;
      }
    }
  }

  > div.d3-chart-tooltip-list-item {
    display: table-row;

    &.closest-value {
      color: white;
      font-weight: 600;
    }

    > div.d3-chart-tooltip-series-name {
      display: flex;
      align-items: center;

      > span.mdi {
      }
    }

    > div.d3-chart-tooltip-value {
      text-align: right;
      padding-left: 15px;
      display: table-cell;
    }
  }

  // &.d3-hives-weight-container {
  //   display: flex;
  //   flex-direction: column;

  //   > div. > div.hive-weight-container {
  //     display: flex;
  //     flex-wrap: nowrap;
  //     flex-direction: row;
  //     align-items: center;

  //     > div.hive-color {
  //       width: 10px;
  //       height: 10px;
  //       margin-right: 5px;
  //     }

  //     > span {
  //       color: white;
  //       font-size: 12px;
  //     }
  //   }
  // }
}
